import {
  AuctionBiddingEngine,
  AuctionType,
  EAuctionBiddingEngine,
  EAuctionType,
} from '@nx/global-types'
import { cleanDoubleSlashes } from '@nx/populate-url'

import { domainBuilder } from '../domainBuilder/domainBuilder'

export function buildLegacyAuctionPath(auctionId: string | number) {
  return `/auctions/${auctionId}/`
}

type BuildAuctionPathProps = {
  auctionId: string | number
  auctionType: EAuctionType
  biddingEngine: EAuctionBiddingEngine
  domain?: string
  slug?: string
  url?: string
}

export function buildAuctionPath({
  auctionId,
  auctionType,
  biddingEngine,
  domain,
  slug,
  url,
}: BuildAuctionPathProps) {
  if (url) {
    if (biddingEngine === AuctionBiddingEngine.BUK) {
      return `${url}?utm_source=bonhams.com`
    }

    return url
  }

  let path = ''

  if (
    auctionType === AuctionType.live &&
    biddingEngine === AuctionBiddingEngine.A3
  ) {
    path = buildLegacyAuctionPath(auctionId)

    if (domain) {
      path = domainBuilder(domain, path)
    }

    return cleanDoubleSlashes(path)
  }

  path = `/auction/${auctionId}/`

  if (slug) {
    path = `${path}${slug}/`
  }

  if (domain) {
    path = domainBuilder(domain, path)
  }

  return cleanDoubleSlashes(path)
}

export const isAnLDCAuction = (
  auctionType: EAuctionType,
  auctionRunsOnAM: boolean
) => auctionType === AuctionType.live && !auctionRunsOnAM
